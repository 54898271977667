.formInput {
    @apply focus:outline-none border-0 p-2 w-full text-black;
}
.formSubmit {
    @apply inline-block !important;
    @apply bg-gray-100 !important;
    @apply text-gray-900 text-sm !important;
    @apply py-2 px-6 rounded-md !important;
    @apply transition-colors !important;
    @apply hover:bg-blue-600 hover:text-white !important;
}
