.embla__button {
    appearance: none;
    @apply bg-[#FCFCFC];
    touch-action: manipulation;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    color: rgb(54, 49, 61);
    @apply flex items-center justify-center;
    @apply rounded-full z-10;
}

.embla__button:disabled {
    color: rgb(192, 192, 192);
}

.embla__button__svg {
    width: 35%;
    height: 35%;
}
