.bottomSection {
    @apply text-[#f6f6f6] bg-[#1d1d1f];
}
.bottomWrap {
    @apply grid py-16 gap-10;
    @apply xl:grid-cols-2 xl:gap-28;
}
.formInput {
    @apply focus:outline-none border-0 p-2 w-full text-black;
}
.formSubmit {
    @apply inline-block;
    @apply bg-gray-100;
    @apply text-gray-900 text-sm;
    @apply py-2 px-6 rounded-md;
    @apply transition-colors;
    @apply hover:bg-blue-600 hover:text-white;
}
