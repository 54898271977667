.ClientOSection {
    @apply mx-auto max-w-5xl px-4 py-20;
}
.ItemsWrapper {
    @apply grid gap-6;
    @apply xl:grid-cols-3 xl:gap-8;
}
.Part1Item:first-of-type {
    @apply border-l-4 border-black pl-4;
}
.Part2Item {
    @apply border-l-4 border-black pl-6 py-10 bg-white shadow-lg;
}
.Part2Item ul li {
    @apply text-sm;
}
