.parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.overlay {
    position: absolute;
    inset: 0;
    /* background-color: #1e1e2a; */
    /* opacity: 0.7; */
    background-color: #16163fd1;
    opacity: 0.8;
}
