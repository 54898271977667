.Head {
    @apply flex flex-col items-center justify-center mb-14;
}
.ItemList {
    @apply grid gap-10;
    @apply lg:grid-cols-2 lg:gap-10;
}
.Item {
    @apply border-l-4 border-black pl-6 pr-10 py-6 bg-white shadow-lg;
}
