.formInput {
    @apply focus:outline-none border border-gray-300 p-2 w-full text-black !important;
}
.formSubmit {
    @apply inline-block !important;
    @apply bg-gray-900 !important;
    @apply text-gray-100 text-sm !important;
    @apply py-2 px-6 rounded-md !important;
    @apply transition-colors !important;
    @apply block mx-auto !important;
    @apply hover:bg-blue-600 hover:text-white !important;
}
