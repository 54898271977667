.BannerSection {
    @apply bg-[#F2F2F2] min-h-[30vh] flex flex-col items-center justify-center;
}
.ClientOrientedSection {
    @apply bg-[#FCFCFC];
}
.HeroASection {
    @apply bg-white;
}
.FacilitiesWrapper {
    @apply mx-auto max-w-5xl px-4 py-20;
}
.BenefitsWrapper {
    @apply mx-auto max-w-5xl px-4 pb-40;
}
.BannerSection {
    @apply h-[250px];
}
.BannerSection h1 {
    @apply text-5xl text-center font-extrabold;
}
.HeroBSection {
    @apply mb-[120px];
}
