.VideoContainer {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 宽高比 */
    height: 0;
    overflow: hidden;
}
.VideoContainer video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.TitleList li {
    @apply text-[#0096d4] font-medium cursor-pointer mb-2;
}
.TitleList li:hover {
    @apply text-[#00558C];
}
.TitleList li::marker {
    @apply font-bold text-black;
}
