.navContainer {
    @apply bg-black relative hidden xl:block;
}
.navHeader {
    @apply flex items-center justify-between;
    @apply mx-auto max-w-[1300px];
}
.navLogo {
    @apply flex flex-shrink-0 items-center;
    @apply bg-[#e11e13] h-[80px] px-[35px];
}
.navLogo img {
    @apply max-h-[18px] max-w-[118px];
}
.menuItems {
    @apply flex;
}
.menuButton {
    @apply flex items-center justify-center;
    @apply h-[80px];
}
.divider {
    width: 1px;
    background-color: #dddddd; /* 设置分隔线颜色 */
    margin: 0 16px; /* 设置分隔线与内容的间距 */
}
.column {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.menuLink:hover .customMenuButton {
    color: #00b6ff;
}
